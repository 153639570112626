import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../@ft/auth/guards';
import { AppComponent } from './app.component';

const routes: Routes = [

  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'welcome'
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
      },
      {
        path: 'logout',
        loadChildren: () => import('./pages/authentication/logout/logout.module').then(m => m.LogoutModule),
      },
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('../@autmos/autmos.module').then(m => m.AutmosModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
