import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Needed for Touch functionality of Material Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PendingInterceptorModule } from '../@fury/shared/loading-indicator/pending-interceptor.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { GraphQLModule } from '../@ft/shared/graphql/graphql.module';
import { HttpErrorInterceptor } from '../@ft/interceptors/http-error.interceptor';
import { BackdropModule } from '../@fury/shared/backdrop/backdrop.module';
import { LoadingIndicatorModule } from '../@fury/shared/loading-indicator/loading-indicator.module';
import { ToolbarModule } from '../@ft/layout/toolbar/toolbar.module';
import { FlexModule } from '@angular/flex-layout';
import { AuthModule } from '../@ft/auth';
import { SidenavModule } from '../@ft/layout/sidenav/sidenav.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  imports: [
    // Angular Core Module // Don't remove!
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Fury Core Modules
    AppRoutingModule,

    // required by task toolbar service
    GraphQLModule.forRoot(),
    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)

    // Google Maps Module
    /*
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),
    */
    // Displays Loading Bar when a Route Request or HTTP Request is pending
    PendingInterceptorModule,

    // Register a Service Worker (optional)
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    AuthModule,
    BackdropModule,
    LoadingIndicatorModule,
    ToolbarModule,
    FlexModule,
    SidenavModule.forRoot()
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom'
      } as MatSnackBarConfig
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
  ]
})
export class AppModule {
}
