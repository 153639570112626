import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private snackBar: MatSnackBar) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            switch (err.status) {
              case 400:
                this.snackBar.open('Die Anfrage war ungültig, überprüfen Sie die Daten.');
                break;

              case 401:
                // handled by auth error interceptor
                break;
              case 403:
                this.snackBar.open('Sie haben nicht die benötigten Rechte für diese Aktion.');
                break;
              case 404:
                this.snackBar.open('Diese Anfrage wurde nicht gefunden.');
                break;

              case 408:
                this.snackBar.open('Der Server hat nicht rechtzeitig geantwortet, probieren Sie es erneut.');
                break;

              case 500:
                this.snackBar.open('Ein Fehler ist bei der Anfrage auf dem Server aufgetreten.');
                break;
            }

          return throwError(err);
        }));
    }
}
