import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TokenModel, User, UserModel, UserToken } from '../../user.model/user';
import { environment } from '../../../environments/environment';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import moment from 'moment/moment';
import 'moment/locale/de';
import { RelatedModel } from '../../../@autmos/@autmos-core/models/related.model';

moment.locale('de');

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User | null>;
  private currentUserModelSubject: BehaviorSubject<UserModel | null>;
  private currentTokenSubject: BehaviorSubject<UserToken | null>;
  public currentUser: Observable<UserModel | null>;
  public currentToken: Observable<UserToken | null>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User | null>(this.loadFromLocalStorage());
    this.currentUserModelSubject = new BehaviorSubject<UserModel | null>(null);
    this.currentTokenSubject = new BehaviorSubject<UserToken | null>(null);

    this.currentUserSubject.subscribe(u => {
      this.currentUserModelSubject.next(u ? u.user : null);
      this.currentTokenSubject.next(u ? <UserToken>{
        token: u.token,
        model: this.decodeUserToken(u)
      } : null);
    });

    this.currentUser = this.currentUserModelSubject.asObservable();
    this.currentToken = this.currentTokenSubject.asObservable();
  }

  public get currentTokenValue(): UserToken | null {
    return this.currentTokenSubject.value;
  }

  public get currentUserValue(): UserModel | null {
    return this.currentUserModelSubject.value;
  }

  public get header() {
    return {
      'X-Auth-Token': this.currentTokenValue!.token
    };
  }

  decodeToken(token: string): TokenModel {
    return jwt_decode(token);

  }

  decodeUserToken(user: User): TokenModel {
    return this.decodeToken(user.token);
  }

  loadFromLocalStorage(): User | null {
    const token = JSON.parse(localStorage.getItem('currentUser')!);

    if (token && this.validateUserToken(token)) {
      return token;
    }

    localStorage.removeItem('currentUser');
    return null;
  }

  validateUserToken(user: User): boolean {
    return this.validateToken(user.token);
  }

  validateToken(token: string): boolean {
    const decodedToken = this.decodeToken(token);
    return moment.unix(decodedToken.exp).isAfter();
  }


  login(email: string, password: string, remember_me: boolean) {
    return this.http.post<User>(`${environment.auth}/sign-in`, {email, password, remember_me})
      .pipe(
        map(response => {
          console.log(response);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(response));
          this.currentUserSubject.next(response);
          return response.user;
        }),
        catchError(this.loginErrorHanler)
      );
  }

  containsJwtGetParamter(route: ActivatedRouteSnapshot) {
    return !!route.queryParamMap.get('jwt');
  }

  loginWithToken(route: ActivatedRouteSnapshot) {
    const jwt = route.queryParamMap.get('jwt')!;
    if (this.validateToken(jwt)) {
      const decodedJwt = this.decodeToken(jwt);
      const user: User = {
        user: {
          roles: [],
          personId: decodedJwt.person_id,
          firstName: decodedJwt.first_name,
          lastName: decodedJwt.last_name,
          kudenart: decodedJwt.kundenart,
          kundengruppe: decodedJwt.kundengruppe,
          kundeId: decodedJwt.kunde_id,
          kundenname: decodedJwt.kundenname
        },
        token: jwt
      };
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.currentUserSubject.next(user);

      return user;
    }

    return null;

  }

  protected loginErrorHanler(error) {
    let errorMessage = '';

    if (error.error) {
      switch (error.error.errorCode) {
        case 'InvalidPassword':
        case 'UserNotFound':
          errorMessage = 'Passwort oder Benutzer ungültig.' +
            (error.error.attemptsAllowed ? 'Sie haben noch ' + error.error.attemptsAllowed + ' Versuch' + (error.error.attemptsAllowed === 1 ? '' : 'e') + ' übrig.' : '');
          break;
        case 'NonActivatedUserEmail':
          errorMessage = 'Benutzer wurde noch nicht aktiviert.';
          break;
        case 'TooManyRequests':
          errorMessage = 'Sie haben zuviele Anfragen gestellt. Sie können es wieder ' + moment(error.error.nextAllowedAttemptTime).fromNow() + ' erneut versuchen.';
          break;
        default:
          errorMessage = 'Ein Fehler ist im System aufgetreten.';
      }
    } else {
      errorMessage = 'Server nicht erreichbar';
    }
    return throwError(errorMessage);
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);

    return this.http.get<any>(`${environment.auth}/sign-out`).subscribe(r => {
        // remove user from local storage to log user out
      },
      f => {

      }
    );
  }

  public getAccess(relatedModel: RelatedModel, id: string|number) {

    const token = this.currentTokenValue;

    if (!token || !token.model.access) {
      return false;
    }

    console.debug('access ', relatedModel, id, token.model.access );

    return token.model.access.find(e =>
      e.relatedModel === relatedModel &&
      (typeof id === 'number' ? e.id === id : e.id.toString(10) === id));
  }

  public getAccesses() {
    const token = this.currentTokenValue;

    if (!token || !token.model.access) {
      return [];
    }

    return token.model.access;
  }
}
