import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '..';


@Injectable({ providedIn: 'root' })
export class UserGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    isAnUser() {
      const userValue =  this.authenticationService.currentTokenValue;
      return userValue ? !!userValue.model.user_id : false;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.isAnUser();
    }
}
