import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '..';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    isAuthed() {
      return this.authenticationService.currentUserValue;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

      if (this.authenticationService.containsJwtGetParamter(route)) {

        const user = this.authenticationService.loginWithToken(route);

        if (user) {
          this.router.navigate( [window.location.pathname] );
        } else {
          this.router.navigate(['/access-forbidden']);
        }
      }

      if (this.isAuthed()) {
          return true;
      }

      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
}
