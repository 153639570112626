import { ModuleWithProviders, NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../../environments/environment';
import { setContext } from '@apollo/client/link/context';

export function provideApollo(httpLink: HttpLink) {


  const uri = environment.graphQL;

  const link = httpLink.create({
    uri: uri,
    withCredentials: true,
    method: 'POST'
  });

  const accept = setContext((_, {headers}) => {

    if (!headers) {
      headers = {};
    }

    if (!headers.accept) {
      headers.Accept = 'text/json';
    }

    return {
      headers: headers
    };
  });

  const defaultOptions = <DefaultOptions>{
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  return {
    link: accept.concat(link),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  };
}

@NgModule({
  imports: [
  ],
  providers: [{
    provide: APOLLO_OPTIONS,
    useFactory: provideApollo,
    deps: [HttpLink],
  }]
})
export class GraphQLModule {
  static forRoot(): ModuleWithProviders<GraphQLModule> {
    return {
      ngModule: GraphQLModule,
      providers: [
        {
          provide: APOLLO_OPTIONS,
          useFactory: provideApollo,
          deps: [HttpLink],
        }
      ]
    };
  }
}

