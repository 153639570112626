export const environment = {
  production: true,
  googleMapsApiKey: 'AIzaSyBI-KuQn08-n0pqdbMO2UEcuBZPAl2E1A4',
  graphQL: 'https://demo-backend.autmos.de/graphql',
  api: 'https://demo-backend.autmos.de/api',
  baseUrl: 'https://demo-backend.autmos.de',
  auth: 'https://demo-backend.autmos.de/auth',
  authApi: 'https://demo-backend.autmos.de/auth/api',
  csvExchangerWebSocket: 'wss://demo-backend.autmos.de/api/exchanger/csv/ws',

  defaultKennzeichenCountryCode: 'D',

};
